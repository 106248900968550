import React, { useEffect } from 'react'
import { SolitoImage as Image } from 'solito/image'
import { useRouter } from 'solito/router'
import { LinearGradient } from 'tamagui/linear-gradient'
import { IMAGE_CODE_FROM_PNG_PIXEL_GRAY } from '@centrito/common/constants'
import PATHS_MERCHANT from '@centrito/common/pathsMerchant'
import MobileWidthLayout from '@centrito/app/layouts/MobileWidthLayoutMerchant'
import { notificationsClient } from '@centrito/app/utils/notifications'
import { Button, YStack, isClient } from '@centrito/ui/src'
import CentritoLogoWhite from '@centrito/ui/src/components/svgs/logos/CentritoLogoWhite'
import {
  HighlightAccentText,
  HighlightBoldText,
} from '@centrito/ui/src/components/text/merchant/highlight'

const LoginScreenComponent: React.FC = () => {
  useEffect(() => {
    notificationsClient.logout()
  }, [])
  const router = useRouter()
  const login = (): void => {
    router.replace(PATHS_MERCHANT.Auth.Login)
  }
  return (
    <MobileWidthLayout
      yStackProps={{
        flexGrow: 1,
      }}
    >
      <Image
        src={
          'https://hwisghycxyclpgtalgfe.supabase.co/storage/v1/object/public/merchant-profile-picture/2149731191%201.png'
        }
        fill
        alt="background"
        contentFit="cover"
        placeholder="blur"
        blurDataURL={IMAGE_CODE_FROM_PNG_PIXEL_GRAY}
      />
      <LinearGradient
        colors={['#00000000', '#000000CC']}
        start={{ x: 0.5, y: 0 }}
        end={{ x: 0.5, y: 1 }}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          borderRadius: 0,
        }}
      />
      <YStack
        paddingBottom={80}
        paddingHorizontal={20}
        flexGrow={1}
        alignItems="center"
        justifyContent="flex-end"
        gap={20}
      >
        <CentritoLogoWhite width={112} height={24} />
        <HighlightAccentText color={'#FFFFFF'} marginBottom={20}>
          El mejor aliado de tu negocio
        </HighlightAccentText>
        <Button
          width={'100%'}
          borderRadius={6}
          height={48}
          onPress={login}
          hoverStyle={{ backgroundColor: 'gray' }}
        >
          <HighlightBoldText color={'#000000'}>Crear cuenta</HighlightBoldText>
        </Button>
        <Button
          width={'100%'}
          borderRadius={6}
          height={48}
          backgroundColor={'transparent'}
          borderWidth={1}
          borderColor={'#FFFFFF'}
          hoverStyle={{ backgroundColor: '#FFFFFF' }}
          onPress={login}
        >
          <HighlightBoldText color={'#FFFFFF'}>Iniciar sesión</HighlightBoldText>
        </Button>
        {isClient && (
          <a
            href="https://samsam.co"
            style={{
              textDecoration: 'none',
              display: 'block',
              width: '100%',
            }}
          >
            <Button
              width={'100%'}
              borderRadius={6}
              height={48}
              backgroundColor="#546EE5"
              borderWidth={1}
              borderColor="#546EE5"
              hoverStyle={{ backgroundColor: '#546EE5' }}
              pressStyle={{ backgroundColor: '#546EE5' }}
            >
              <HighlightBoldText color="#FFFFFF">Ir a samsam.co</HighlightBoldText>
            </Button>
          </a>
        )}
      </YStack>
    </MobileWidthLayout>
  )
}

export const MerchantLoginScreen: React.FC = () => {
  return <LoginScreenComponent />
}
