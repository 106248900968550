import React from 'react'
import { Main, type YStackProps } from '@centrito/ui/src'
import { MOBILE_WIDTH_STYLES } from '@centrito/ui/src/constants'

interface MobileWidthLayoutProps {
  yStackProps?: Omit<YStackProps, 'children'>
  children: React.ReactNode
}

const MobileWidthLayout: React.FC<MobileWidthLayoutProps> = ({ children, yStackProps }) => {
  return (
    <Main flexGrow={1} {...yStackProps} {...MOBILE_WIDTH_STYLES}>
      {children}
    </Main>
  )
}

export default MobileWidthLayout
