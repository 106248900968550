import React from 'react'
import { Text, type TextProps } from 'tamagui'

export const HighlightBoldText: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontFamily="$interFont" fontSize={16} fontWeight="700" {...rest}>
      {children}
    </Text>
  )
}
